import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import whitepaperMockup from '../img/temtum-whitepaper-mockup.png';

const WhitePaper = () => ( <Layout>
    <div>
      <Helmet
        title={'temtum White Paper | temtum Network & Temporal Blockchain Technology'}
        meta={[
          {
            name: 'description',
            content: 'The temtum White Paper is available online or downloadable as a PDF and explores the core technology underpinning the TEM currency.'
          }
        ]}
      />
      <div className="hd-space"/>
      <section className="text-white skipScroll bg-use-world h-100">
        <div className="blueblue video-overlay"/>
        <div className="wow3 video-overlay"/>
        <div className="container">

          <div className="row">
            <div className="col-12 col-md-12 col-lg-6">

              <h1 className="display-2 mt-4 pt-3 pb-3">Temporal
                <span className="font-weight-normal">White Paper</span>
              </h1>

              <p className="lead text-mutedX">
                Our whitepaper is now available for review, with details on our Temporal blockchain, temtum network and currency, cryptography, technology integrations, team, road map and competition.
              </p>
              <a href={'../downloads/temtum-whitepaper.pdf'} className="btn btn-outline-light" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-file-pdf mr-2" />
                Download PDF
              </a>

            </div>
            <div className="col-12 col-md-4 col-lg-4 mx-auto">
              <img src={whitepaperMockup} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)
export default WhitePaper;